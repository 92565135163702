import axios from 'axios';
import { API_ENDPOINT } from "../constants";

export class API_OCR {
    static post = (payload) => new Promise((resolve, reject) => {

        try {
            const instance = axios.create({
                baseURL: API_ENDPOINT,
                timeout: 60000,
                crossdomain: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });

            instance.post('/OCR', payload)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    resolve(error);
                });


        }
        catch (error) {
            reject(new Error(error));
        }
    });
}