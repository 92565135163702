import { createStore } from 'redux';

const INITIAL_STATE = {
    total_db_records: 0,
    app_status_online: false,
    app_status_api_online: false,
    app_status_remoteDB: false,
    app_status_isLoading: false,
    app_settings: {
        screenSaver: false,
        darkMode: true,
        authenticated: false
    },
};

function app_stats_reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'UPDATE_TOTAL_RECORDS':
            return { ...state, total_db_records: action.total };
        case 'UPDATE_STATUS_ONLINE':
            return { ...state, app_status_online: action.status };
        case 'UPDATE_STATUS_API_ONLINE':
            return { ...state, app_status_api_online: action.status };
        case 'UPDATE_STATUS_REMOTEDB':
            return { ...state, app_status_remoteDB: action.status };
        case 'UPDATE_LOADING':
            return { ...state, app_status_isLoading: action.status };
        case 'UPDATE_LOADING':
            return { ...state, app_status_isLoading: action.status };
        case 'UPDATE_SETTINGS_AUTH':
            return {
                ...state,
                app_settings: {
                    ...state.app_settings,
                    authenticated: action.settings
                }
            };
        case 'UPDATE_SETTINGS_DARKMODE':
            return {
                ...state,
                app_settings: {
                    ...state.app_settings,
                    darkMode: action.settings
                }
            };
        case 'UPDATE_SETTINGS_SCREENSAVER':
            return {
                ...state,
                app_settings: {
                    ...state.app_settings,
                    screenSaver: action.settings
                }
            };
        default:
            return state;
    }
}

const store = createStore(app_stats_reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;