import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from '../store/actions';

import { useTranslation } from 'react-i18next';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import FirebaseService from '../services/firebaseService';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        //backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleFBLogin = async event => {
        dispatch(Actions.updateStatusIsLoading(true));
        event.preventDefault();
        try {
            const user = await FirebaseService.loginWithFB().then(function (result) {
                //var token = result.credential.accessToken;
                var user = result.user;
                dispatch(Actions.updateStatusIsLoading(false));
                if (user) {
                    dispatch(Actions.updateSettingsAuth(true));
                    enqueueSnackbar(t('Welcome'), { variant: 'success' });
                }
            }).catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                var email = error.email;
                var credential = error.credential;
                dispatch(Actions.updateStatusIsLoading(false));
                enqueueSnackbar(t('Authentication failed') + "...", { variant: 'error' });
            });


        } catch (error) {
            dispatch(Actions.updateStatusIsLoading(false));
            enqueueSnackbar(t('Authentication failed') + "...", { variant: 'error' });
        }

    }

    const handleLogin = async event => {
        dispatch(Actions.updateStatusIsLoading(true));
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
            const user = await FirebaseService.login(email.value, password.value);
            dispatch(Actions.updateStatusIsLoading(false));
            if (user) {
                dispatch(Actions.updateSettingsAuth(true));
                enqueueSnackbar(t('Welcome'), { variant: 'success' });
            }

        } catch (error) {
            dispatch(Actions.updateStatusIsLoading(false));
            enqueueSnackbar(t('Authentication failed') + "...", { variant: 'error' });
        }
    };

    const { t, i18n } = useTranslation();

    return (

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('Sign in')}
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleLogin}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t("Email Address")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {t('Sign in')} </Button>
                </form>

                {/*}
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleFBLogin}
                >       
                FB LOGIN </Button>*/}

            </div>
        </Container >
    );
}