import { firebaseDatabase, firebaseImpl } from '../firebaseUtils';
import firebase from 'firebase';

export default class FirebaseService {

    static remove = (id, node) => {
        return firebaseDatabase.ref(node + '/' + id).remove();
    };

    static pushPic = (node, objToSubmit) => {

    };

    static pushData = (node, objToSubmit) => {
        const ref = firebaseDatabase.ref(node).push();
        const id = firebaseDatabase.ref(node).push().key;
        ref.set(objToSubmit);
        return id;
    };

    static getDataList = (nodePath, callback, size = 10) => {

        let query = firebaseDatabase.ref(nodePath)
            .limitToLast(size);
        query.on('value', dataSnapshot => {
            let items = [];
            dataSnapshot.forEach(childSnapshot => {
                let item = childSnapshot.val();
                item['key'] = childSnapshot.key;
                items.push(item);
            });
            callback(items);
        });

        return query;
    };

    static loginWithFB = () => {
        var provider = new firebase.auth.FacebookAuthProvider();
        return firebaseImpl.auth().signInWithPopup(provider);
    };

    static login = (email, password) => {
        return firebaseImpl.auth().signInWithEmailAndPassword(email, password);
    };

    static logout = () => {
        return firebaseImpl.auth().signOut();
    };

} 