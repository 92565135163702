import React from 'react';

import InputForm from "./InputForm/index";

import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

export default function Front(props) {
    return (
        <Container>
            <CssBaseline />
            <Grid item xs={12}>
                <InputForm refresh={props.fetchData} />
            </Grid>
        </Container>
    )
}