export default {
    direction: 'ltr',
    palette: {
        type: 'dark',
        primary: {
            main: '#c97f07'
        },
        secondary: {
            main: '#1289ac'
        },

    },
};