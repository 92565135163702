import React, { useState, useEffect } from 'react';

import * as Actions from '../store/actions';
import { useSelector, useDispatch } from 'react-redux';

import Loader from './Loader';

import SagiperStyle from '../App.css';

import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PicLogo from '../assets/sagiper-logo.png'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import IconButton from '@material-ui/core/IconButton';


import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import ListSubheader from '@material-ui/core/ListSubheader';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import Snackbar from '@material-ui/core/Snackbar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { db, remoteDatabase } from '../api';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },

    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },

    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        display: 'flex',
    },

    paper: {
        margin: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
    gridList: {

    },

    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },

}));

export default function Back() {

    const [elements, setElements] = useState([]);
    const [showSnack, setshowSnack] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
        const liveDBUpdater = db.changes({
            since: 'now',
            live: true,
            include_docs: true,
        }).on('change', () => {
            fetchData();
        });

    }, ['']);

    const fetchData = () => {
        dispatch(Actions.updateStatusIsLoading(true));
        db.allDocs({
            include_docs: true,
            attachments: true,
            descending: true
        }).then(result => {
            const rows = result.rows;
            setElements(rows.map(row => row.doc));
            dispatch(Actions.updateStatusIsLoading(false));

        }).catch((err) => {
            console.log(err);
            dispatch(Actions.updateStatusIsLoading(false));
        });
    }

    return (
        <Container component="main">
            <CssBaseline />

            <Grid className={classes.container} container>
                <Grid item xs={12} sm={6}>
                    <Paper square className={classes.paper}>
                        <List className={classes.list}>
                            {elements.map((item, index) => (
                                <React.Fragment key={item._id}>
                                    <ListItem>
                                        {item._attachments && item._attachments.pic && (
                                            <ListItemAvatar>
                                                <Avatar alt="Profile Picture" src={`data:image/png;base64,${item._attachments.pic.data}`} />
                                            </ListItemAvatar>)}
                                        <ListItemText primary={item.userData.name} secondary={item.userData.name + " | " + item._id.slice(0, 10)} />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}