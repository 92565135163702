/* REDUX ACTIONS */
export function updateTotalRecordsAction(total) {
    return { type: 'UPDATE_TOTAL_RECORDS', total }
}

export function updateStatusRemoteDb(status) {
    return { type: 'UPDATE_STATUS_REMOTEDB', status }
}

export function updateStatusOnline(status) {
    return { type: 'UPDATE_STATUS_ONLINE', status }
}

export function updateStatusAPI(status) {
    return { type: 'UPDATE_STATUS_API_ONLINE', status }
}

export function updateStatusIsLoading(status) {
    return { type: 'UPDATE_LOADING', status }
}

export function updateSettingsDarkmode(settings) {
    return { type: 'UPDATE_SETTINGS_DARKMODE', settings }
}

export function updateSettingsScreensaver(settings) {
    return { type: 'UPDATE_SETTINGS_SCREENSAVER', settings }
}

export function updateSettingsAuth(settings) {
    return { type: 'UPDATE_SETTINGS_AUTH', settings }
}