export const V = '212112021';
export const COUCH_URL = 'https://db.btsdev.pt';
export const COUCH_DATABASE = 'sagiper'
export const IDLE_TIME = 300000
export const HEARTBEAT_DB_TIME = 30000
export const API_ENDPOINT = 'https://backend-sagiper.besttradeshowapp.com/api/v1';
export const OCR_INSTALLED = false;  
//export const API_ENDPOINT = 'http://localhost:9999';

/* 
npm install
npm run-script build
firebase deploy
*/