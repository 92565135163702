import React, { useState, useEffect, useRef } from 'react';
import * as Actions from '../../store/actions';
import { useSnackbar } from 'notistack';

import { API_OCR } from '../../services/OCRService';
import { Formik } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateStatusIsLoading } from '../../store/actions';
import Resizer from 'react-image-file-resizer';
import { useTranslation } from 'react-i18next';

import { Form } from "./form";
import Paper from "@material-ui/core/Paper";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import { green } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import * as Yup from "yup";
import { db, remoteDatabase } from '../../api';

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    rgpd_check: Yup.bool().oneOf([true], 'Field must be checked'),
    email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
    //address: Yup.string().required("Address is required"),
    //gender: Yup.string("Pick a gender").required("Gender is required"), */
    //country: Yup.string("Pick your Country").required("Country is required"),* /
});

const useStyles = makeStyles(theme => ({

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme
            .spacing(5)}px`
    },

    paperCameraButtons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(1.5)}px`,
        marginBottom: theme.spacing(4),
        width: '100%',
    },

    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },

    picAvatar: {
        margin: theme.spacing(1),
    },

    picAttachment: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        maxWidth: '100%',
        height: 'auto'
    },

    chip: {
        margin: theme.spacing(1),
    },

    submit: {
        border: 0,
        borderRadius: '3px',
        color: 'white',
        height: 48,
        padding: '0 30px'
    },

    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    card: {
        maxWidth: 345,
        marginTop: '50px'
    },
    formControl: {
        margin: theme.spacing(3),
    },

    button: {
        margin: theme.spacing(1),
    },

}));

function InputForm(props) {

    const initVals = {
        name: "",
        email: "",
        phone: "",
        role: "",
        country: "",
        company: "",
        address: "",
        notes: "",
        pickedRequests: [],
        pickedProducts: [],
        rgpd_check: false,
        request_newsletters_check: false
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [pic, setPic] = useState(null);
    const [attachment, setAttachment] = useState(null);

    const [ocrResultArr, setOcrResultArr] = useState(null);
    const [OCRTXTToFill, setOCRTXTToFill] = useState('');

    const [initialFormData, setInitialFormData] = useState(initVals);

    const [loading, setLoading] = useState(false);
    const [dialogOCR, setDialogOCR] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const { t, i18n } = useTranslation();

    const defaultCheckboxes = {
        requests: [
            t('Tables'), t('Tech. Info'), t('Catalogs'), t('Product Samples')
        ],
        products: ["Sagifloor", "Sagiwall", "Sagirev", "Sagideck"],
    }

    const appNetworkStatus = useSelector(state => state.app_status_online);
    const appAPIStatus = useSelector(state => state.app_status_api_online);

    const resetValues = () => {
        setPic(null);
        setAttachment(null);
        setOcrResultArr(null)
        setInitialFormData(initVals);
    }

    const handleDialogOCROpen = (element) => {
        setDialogOCR(true);
        setOCRTXTToFill(element);
    };

    const handleDialogPickedField = (field) => {
        setInitialFormData({ ...initialFormData, [field]: OCRTXTToFill });
        handleDialogOCRClose(OCRTXTToFill);
    }

    const handleDialogOCRClose = (OCRTXTToFill) => {

        setDialogOCR(false);
        var array = [...ocrResultArr];
        const item = array.find(x => x.LineText === OCRTXTToFill);
        const index = array.indexOf(item);
        if (index !== -1) {
            array.splice(index, 1);
            setOcrResultArr(array);
        }
    };

    const timer = useRef();
    const handleFileChange = (e) => {
        let fileInput = false;
        const type = e.target.name;
        if (e.target.files[0]) {
            fileInput = true
        }
        if (fileInput) {
            Resizer.imageFileResizer(
                e.target.files[0],
                3000,
                1000,
                'JPEG',
                100,
                0,
                uri => {
                    if (type == "card")
                        handleOCR(uri);
                    else if (type == "attachment")
                        setAttachment(uri);
                    else
                        setPic(uri);
                },
                'base64'
            );
        }
    }

    const handleChipClick = (element) => {
        handleDialogOCROpen(element);
    }

    const handleOCR = (cardPic) => {
        if (cardPic) {
            setLoading(true);

            API_OCR.post({ pic: cardPic }).then((resolvedValue) => {
                if (resolvedValue.data.IsErroredOnProcessing) {
                    enqueueSnackbar(resolvedValue.data.ErrorMessage, { variant: 'error' });
                } else {
                    const lines = resolvedValue.data.ParsedResults[0].TextOverlay.Lines.map(line => line.LineText + " ");
                    setOcrResultArr(resolvedValue.data.ParsedResults[0].TextOverlay.Lines);
                    enqueueSnackbar(t('OCR SUCCESSFULLY RUN IN') + " " + parseFloat(resolvedValue.data.ProcessingTimeInMilliseconds) / 1000 + " " + t("SECONDS") + " " + t("AND DETECTED") + " " + resolvedValue.data.ParsedResults[0].TextOverlay.Lines.length + " " + "ITEMS", { variant: 'success' });
                };
                setLoading(false);
                clearTimeout(timer.current);
            }).catch(() => {
                //enqueueSnackbar('SOMETHING WENT WRONG...', { variant: 'error' });
            })

            timer.current = setTimeout(() => {
                setLoading(false);
                enqueueSnackbar(t('OCR API IS BUSY... PLEASE TRY AGAIN LATER.'), { variant: 'warning' });
            }, 60000);
        }
    }

    const submitValues = (submitedData, actions) => {

        dispatch(updateStatusIsLoading(true));

        const obj = {
            _id: new Date().toJSON(),
            name: submitedData.email,
            userData: submitedData
        }

        if (pic || attachment) {
            obj._attachments = {};

            if (pic) {
                obj._attachments.pic = {
                    content_type: 'image/jpeg',
                    data: pic.split(',')[1]
                }
            }

            if (attachment) {
                obj._attachments.attachment = {
                    content_type: 'image/jpeg',
                    data: attachment.split(',')[1]
                }
            }
        }



        db.put(obj).then(function () {
            dispatch(updateStatusIsLoading(false));
            props.refresh(this);
            enqueueSnackbar(t("THANK YOU!"), { variant: 'success' });
            actions.resetForm();
            resetValues();
        })
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <Paper elevation={1} className={classes.paper}>
                    <Formik
                        render={props =>
                            <Form
                                {...props}
                                classes={classes}

                                requests={defaultCheckboxes.requests}
                                products={defaultCheckboxes.products}

                                handleFileChange={handleFileChange}
                                //handleOCR={handleOCR}
                                handleChipClick={handleChipClick}

                                //hasCardPic={cardPic ? true : false}
                                appNetworkStatus={appNetworkStatus}
                                appAPIStatus={appAPIStatus}

                                //handleFormChanges={handleFormChange} 

                                ocrResultArr={ocrResultArr}
                                attachmentTaken={attachment}
                                picTaken={pic}
                                loading={loading}

                                dialogOCR={dialogOCR}
                                OCRTXTToFill={OCRTXTToFill}
                                handleDialogOCRClose={handleDialogOCRClose}
                                handleDialogPickedField={handleDialogPickedField}
                            />
                        }
                        enableReinitialize={true}
                        initialValues={initialFormData}
                        validationSchema={validationSchema}
                        onSubmit={submitValues}
                        onReset={resetValues}
                    />


                </Paper>
            </div>
        </React.Fragment>
    );

}

function FinalCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={props.pic}
                    title="User PIC"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.userData.name}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p">
                        {props.userData.email}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>

            </CardActions>
        </Card>
    )
}
export default InputForm