import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCjpBDf-hxQX581UqdZj6yNiAYEAggV9xY",
    authDomain: "bitnami-jgfqo2p7xw.firebaseapp.com",
    databaseURL: "https://bitnami-jgfqo2p7xw.firebaseio.com",
    projectId: "bitnami-jgfqo2p7xw",
    storageBucket: "bitnami-jgfqo2p7xw.appspot.com",
    messagingSenderId: "451425987632",
    appId: "1:451425987632:web:2b60b73d98cab1484691f5"
};

export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseDatabase = firebase.database();